import { createContext, useContext, useEffect, useState } from "react";
import { approveSKLForBridge, approveTokens, bridgeTokens, getTokenBalance, isApproved, quoteSend, SendParamsFee } from "../../web3";
import { TokenType } from "../tokens/tokens";
import { useWallet } from "./walletcontext";
import { chains } from "../chains/chains";
import { FLAG } from "../tokens/flag";
import { ethers } from "ethers";
import ERC20ABI from "../../ERC20abi";
import { polygon, skaleEuropa } from "viem/chains";


interface BridgeContextType {
    amount: string;
    sourceToken: TokenType;
    srcChainId: number;
    dstChainId: number;
    isBridging: boolean;
    bridgeSuccess: boolean;
    isApprove: boolean;
    sendParamsFee: SendParamsFee;
    sourceTokenBalance: string;
    hash: string;
    handleSrcChainChange: (newChainId: number) => void;
    handleDstChainChange: (newChainId: number) => void;
    handleSourceTokenChange: (e: TokenType) => void;
    handleApprove: () => void;
    handleBridge: () => void;
    setAmount: (amount: string) => void;
}

interface BridgeProviderProps {
    children: React.ReactNode;
}

const BridgeContext = createContext<BridgeContextType | undefined>(undefined);

export const BridgeProvider: React.FC<BridgeProviderProps> = ({ children }) => {
    const { signer, switchChain, chain, isWrongChain } = useWallet();
    const [state, setState] = useState({
        amount: '',
        isApprove: false,
        sendParamsFee: { sendParams: {}, nativeFees: '' },
        isBridging: false,
        bridgeSuccess: false,
        sourceTokenBalance: '0',
        srcChainId: chains[polygon.id.toString()].id,
        dstChainId: chains[skaleEuropa.id.toString()].id,
        sourceToken: FLAG,
        hash: '',
    });
    const setPartialState = (newState: { isBridging?: boolean; bridgeSuccess?: boolean; srcChainId?: number; dstChainId?: number; sourceToken?: TokenType; isApprove?: boolean; hash?: string; sourceTokenBalance?: string; sendParamsFee?: SendParamsFee | { sendParams: {}; nativeFees: string; } | { sendParams: {}; nativeFees: string; }; amount?: string; }) => setState((prevState) => ({ ...prevState, ...newState }));

    function listenForBridgeCompletion(userAddress: string) {
        const provider = new ethers.JsonRpcProvider(chains[state.srcChainId].rpcUrls.default.http[0]);
        const contract = new ethers.Contract(state.sourceToken.addresses[state.dstChainId].address, ERC20ABI, provider);
        contract.on("Transfer", (from, to, amount, event) => {
            if (to === userAddress) {
                setPartialState({ isBridging: false, bridgeSuccess: true });
                contract.off("Transfer");
            }
        });
    }
    const handleSrcChainChange = async (newChainId: number) => {
        setPartialState({ srcChainId: newChainId });
        await switchChain(newChainId.toString());
    };

    const handleDstChainChange = async (newChainId: number) => {
        setPartialState({ dstChainId: newChainId });
    };

    const handleSourceTokenChange = (e: TokenType) => {
        setPartialState({ sourceToken: e });
    };

    const handleApprove = async () => {
        console.log("handle Approve")
        if (!signer) {
            console.log("Wallet not connected");
            return;
        }
        const success = await approveTokens(signer, state.amount, state.sourceToken, state.srcChainId);
        console.log("success : " + success)
        setPartialState({ isApprove: success });
    };

    const handleBridge = async () => {
        setPartialState({ isBridging: true, bridgeSuccess: false });
        if (!state.isApprove) {
            console.log("Tokens not approved for bridge");
            return;
        }
        if (!signer) {
            console.log("Wallet not connected");
            return;
        }
        let approveSKL: boolean | undefined;
        if (state.srcChainId == skaleEuropa.id) {
            approveSKL = await approveSKLForBridge(signer, state.sendParamsFee.nativeFees)
            console.log(approveSKL)
            if (approveSKL) {
                const tx = await bridgeTokens(signer, state.sendParamsFee.sendParams, state.sendParamsFee.nativeFees, state.sourceToken, state.srcChainId);
                setPartialState({ hash: tx.hash });
                listenForBridgeCompletion(await signer.getAddress());
            }
        } else {
            const tx = await bridgeTokens(signer, state.sendParamsFee.sendParams, state.sendParamsFee.nativeFees, state.sourceToken, state.srcChainId);
            setPartialState({ hash: tx.hash });
            listenForBridgeCompletion(await signer.getAddress());
        }

    };

    useEffect(() => {
        const polygonMainnetId = polygon.id;
        const europaId = skaleEuropa.id;

        if (chain) {
            setPartialState({ srcChainId: chain.id });
            if (chain?.id === polygonMainnetId) {
                setPartialState({ dstChainId: europaId });
            } else if (state.srcChainId === europaId) {
                setPartialState({ dstChainId: polygonMainnetId });
            }
        }
        const fetchGasEstimate = async () => {
            if (!isWrongChain && signer && state.sourceToken && state.srcChainId && state.dstChainId) {
                let balance = await getTokenBalance(signer, state.sourceToken, state.srcChainId);
                console.log("balance : " + balance)
                console.log("state.amount : " + state.amount)
                setPartialState({ sourceTokenBalance: balance || '0' });
                try {
                    if (state.amount) {
                        const isApprove = await isApproved(signer, state.amount, state.sourceToken, state.srcChainId);
                        console.log("isApprove : " + isApprove)
                        setPartialState({ isApprove });
                        const fees = await quoteSend(signer, state.amount, state.sourceToken, state.srcChainId, chains[state.dstChainId]);
                        console.log("fees", fees);
                        setPartialState({ sendParamsFee: fees || { sendParams: {}, nativeFees: '' } });
                    }
                } catch (error) {
                    console.error("Error during gas Estimate:", error);
                    setPartialState({ sendParamsFee: { sendParams: {}, nativeFees: '' } });
                }
            }
        };
        fetchGasEstimate();
    }, [chain, signer, state.srcChainId, state.amount]);

    const value = { ...state, handleSrcChainChange, handleDstChainChange, handleSourceTokenChange, handleApprove, handleBridge, setAmount: (amount: string) => setPartialState({ amount }) };

    return <BridgeContext.Provider value={value}>{children}</BridgeContext.Provider>;
};

export const useBridge = () => {
    const context = useContext(BridgeContext);
    if (context === undefined) {
        throw new Error("useBridge must be used within a BridgeProvider");
    }
    return context;
};
